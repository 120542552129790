<template>
  <div class="relative w-[292px]">
    <button
      aria-label="accesses"
      class="w-[292px] relative z-[1] border border-[#E2E2E2] rounded-lg group peer focus:!rounded-b-none
            [transition:border-radius,_.3s] delay-200 focus:delay-0 cursor-pointer py-[10px] px-[14px] flex
            items-center justify-between outline-none border-none"
    >
      <div class="flex items-center gap-[8px]">
        <i
          class="icon k-global text-[24px] text-[#292D32]"
          :class="[currentAccess.icon]"
        />
        <span>{{ currentAccess.label }}</span>
      </div>
      <span class="icon k-arrow-down-1 text-[24px] group-focus:rotate-180 transition-transform duration-300 delay-200 group-focus:delay-0" />
    </button>
    <div
      style="border-top: 0 !important; border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;"
      class="absolute top-[45px] opacity-0 peer-focus:opacity-100 transition-opacity duration-300 select-none delay-200
              peer-focus:delay-0 pointer-events-none peer-focus:pointer-events-auto hover:pointer-events-auto z-[1]
              peer-focus:z-[2] hover:z-[2] bg-white pb-[10px] px-[14px] border border-[#E2E2E2] rounded-lg"
    >
      <div class="border-t border-[#E2E2E2] pt-[16px] flex flex-col gap-[8px]">
        <b class="font-semibold block mb-[4px]">Siapa saja yang bisa melihat berita ini?</b>
        <label
          v-for="option, i in accessOption"
          :key="i"
          :for="option.value"
          aria-label="access"
          class="flex justify-between items-center cursor-pointer group py-[4px] group"
        >
          <input
            :id="option.value"
            :value="option.value"
            :checked="access == option.value"
            type="radio"
            name="access"
            class="hidden"
            @change="$emit('update:access', option.value)"
          >
          <div class="flex items-center gap-[8px]">
            <i
              class="icon text-[24px] text-[#292D32]"
              :class="[option.icon]"
            />
            <span class="text-[16px] text-[#333333] group-hover:tracking-wider group-active:tracking-tight [transition:letter-spacing,_.3s]">{{ option.label }}</span>
          </div>
          <div class="w-[1.26rem] h-[1.25rem] rounded-full border-2 border-[#F95031] flex items-center justify-center">
            <span
              class="w-[.877rem] h-[.875rem] bg-[#F95031] rounded-full"
              :class="access == option.value ? 'block visible' : 'hidden'"
            />
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    access: {
      type: String,
      default: '',
    },
    accessOption: {
      type: Array,
      default: () => [
        { value: 'public', label: 'Public', icon: 'k-global' },
        { value: 'partner', label: 'Partner Terasign', icon: 'k-people' },
      ],
    },
  },
  computed: {
    currentAccess() {
      return this.accessOption.find(a => a.value === this.access)
    },
  },
}
</script>
